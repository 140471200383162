import { useEffect, useState } from "react";
import axios from "axios";
import HOTSKUCSS from "../css/hotsku.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function HOTSKU() {
  const initialState = (defaultValue) =>
    Object.fromEntries(
      Array.from({ length: 5 }, (_, i) => [`monitor${i + 1}`, defaultValue])
    );

  const [inputSKU, setInputSKU] = useState(initialState(""));
  const [isSubmitClicked, setIsSubmitClicked] = useState(initialState(false));
  const [showRemoveInfo, setShowRemoveInfo] = useState(initialState(""));
  const [isDeleteClicked, setIsDeleteClicked] = useState(initialState(false));
  const [showInfo, setShowInfo] = useState(initialState(""));

  const handleSubmitHOTSku = async (e) => {
    e.preventDefault();

    const type = e.target.name;
    let sku = inputSKU[type];

    console.log(type);

    if (type === "monitor5" && sku.includes(" ")) {
      sku = sku.split(" ").filter((_) => _ !== "");
    } else if (type === "monitor5") {
      sku = [sku];
    }

    setIsSubmitClicked((prev) => ({ ...prev, [type]: true }));

    setTimeout(() => {
      try {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `/api/hotskucreate?type=${type}`,
          data: {
            sku: sku,
          },
        };

        axios(config)
          .then(() => {
            setShowInfo((prev) => ({ ...prev, [type]: "sent" }));
          })
          .catch(() => {
            setShowInfo((prev) => ({ ...prev, [type]: "error" }));
          });
      } catch (error) {
        setShowInfo((prev) => ({ ...prev, [type]: "error" }));
      }

      setIsSubmitClicked((prev) => ({ ...prev, [type]: false }));

      setTimeout(() => {
        setShowInfo((prev) => ({ ...prev, [type]: "" }));
      }, 3000);
    }, 1000);
  };

  const handleDeleteHOTSku = (e) => {
    e.preventDefault();

    const type = e.target.name;
    let sku = inputSKU[type];

    if (type === "monitor5" && sku.includes(" ")) {
      sku = sku.split(" ").filter((_) => _ !== "");
    } else if (type === "monitor5") {
      sku = [sku];
    }

    setIsDeleteClicked((prev) => ({ ...prev, [type]: true }));

    setTimeout(() => {
      try {
        const config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `/api/hotskudelete?type=${type}`,
          data: {
            sku: sku,
          },
        };

        axios(config)
          .then(() => {
            setShowRemoveInfo((prev) => ({ ...prev, [type]: "sent" }));
          })
          .catch(() => {
            setShowRemoveInfo((prev) => ({ ...prev, [type]: "error" }));
          });
      } catch (error) {
        setShowRemoveInfo((prev) => ({ ...prev, [type]: "error" }));
      }

      setIsDeleteClicked((prev) => ({ ...prev, [type]: false }));

      setTimeout(() => {
        setShowRemoveInfo((prev) => ({ ...prev, [type]: "" }));
      }, 3000);
    }, 1000);
  };

  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 100,
      easing: "ease-in-out-quart",
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [showInfo]);

  return (
    <div className={HOTSKUCSS["root-hotsku-shoes"]}>
      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor1"
      >
        <label htmlFor="inputName">Enter the ASICS product link</label>
        <input
          type="text"
          name="inputSKU1"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({ ...inputSKU, monitor1: e.target.value })
          }
        />
        <button
          className={isSubmitClicked.monitor1 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor1 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor1 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor1 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor1"
        >
          {showRemoveInfo.monitor1 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor1 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor2"
      >
        <label htmlFor="inputName">Enter the JD product link</label>
        <input
          type="text"
          name="inputSKU2"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({ ...inputSKU, monitor2: e.target.value })
          }
        />
        <button
          className={isSubmitClicked.monitor2 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor2 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor2 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor2 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor2"
        >
          {showRemoveInfo.monitor2 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor2 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor3"
      >
        <label htmlFor="inputName">Enter the FNL product link</label>
        <input
          type="text"
          name="inputSKU3"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({ ...inputSKU, monitor3: e.target.value })
          }
        />
        <button
          className={isSubmitClicked.monitor3 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor3 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor3 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor3 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor3"
        >
          {showRemoveInfo.monitor3 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor3 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor4"
      >
        <label htmlFor="inputName">Enter the JD/FNL SKU</label>
        <input
          type="text"
          name="inputSKU4"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({ ...inputSKU, monitor4: e.target.value })
          }
        />
        <button
          className={isSubmitClicked.monitor4 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor4 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor4 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor4 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor4"
        >
          {showRemoveInfo.monitor4 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor4 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>

      <form
        className={HOTSKUCSS["form-container"]}
        onSubmit={handleSubmitHOTSku}
        method="post"
        name="monitor5"
      >
        <label htmlFor="inputName">Enter the Adidas SKU</label>
        <input
          type="text"
          name="inputSKU5"
          id="inputSKU"
          onChange={(e) =>
            setInputSKU({ ...inputSKU, monitor5: e.target.value })
          }
        />
        <button
          className={isSubmitClicked.monitor5 ? HOTSKUCSS["spin"] : ""}
          type="submit"
        >
          {showInfo.monitor5 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU added successfully</p>
            </div>
          )}
          {showInfo.monitor5 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Add
          <span className={HOTSKUCSS["spinner"]}></span>
        </button>
        <button
          className={isDeleteClicked.monitor5 ? HOTSKUCSS["spin"] : ""}
          onClick={handleDeleteHOTSku}
          type="button"
          name="monitor5"
        >
          {showRemoveInfo.monitor5 === "sent" && (
            <div
              className={HOTSKUCSS["info-green"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="500"
              data-aos-delay="0"
            >
              <p>SKU deleted successfully</p>
            </div>
          )}
          {showRemoveInfo.monitor5 === "error" && (
            <div
              className={HOTSKUCSS["info-red"]}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="flip-down"
              data-aos-duration="250"
              data-aos-delay="0"
            >
              <p>Error</p>
            </div>
          )}
          Remove
        </button>
      </form>
    </div>
  );
}

export default HOTSKU;
